<template>
  <v-layout :class="horizontal ? 'align-center' : 'column'">
    <v-layout justify-center>
      <v-flex>
        <v-text-field
          shrink
          :label="label"
          :placeholder="placeholder"
          outlined
          required
          :rules="rules"
          v-model="text"
          class="default-input-style mr-1 ml-4"
          :disabled="disabled"
          :hide-details="hideDetails"
          :clearable="clearable"
        >
          <template v-if="autosave" slot="append">
            <AutosaveIcon :value="status"></AutosaveIcon>
          </template>
        </v-text-field>
      </v-flex>
      <v-flex shrink class="mr-2">
        <PhoneButton :phoneNumber="text"/>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import Validators from "@/mixins/Validators";
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [Validators, ModificationMixin],
  props: {
    value: {},
    label: {},
    placeholder: {},
    disabled: {
      default: false,
    },
    rules: {},
    required: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    horizontal: {
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    PhoneButton: () => import("@/components/buttons/PhoneButton"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("text");
    }
  },
};
</script>